import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useStaticQuery, graphql } from 'gatsby' 
import { getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'
import { Container } from '@material-ui/core' 

export default function ContactBanner() {
    const { t } = useTranslation();

    const { placeholderImage } = useStaticQuery(
        graphql`
          query {
            placeholderImage: file(relativePath: { eq: "background-contact-banner1.png" }) {
              childImageSharp {
                gatsbyImageData(quality:100,layout:FULL_WIDTH,placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
              }
            }
          }
        `
    )
    const image = getImage(placeholderImage)

    // Use like this:
    const bgImage = convertToBgImage(image)

    return (
        <BackgroundImage
            Tag="section"
            {...bgImage}
            style={{ marginBottom: -180 }}
        >
            <Container className='contact-banner-container' fixed style={{ height: 500 }}>
                <h1>{t('Contact')}</h1>
            </Container>
        </BackgroundImage>
    )
}
