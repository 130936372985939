import React from 'react'
import MainLayout from "../layouts/MainLayout"
import Seo from "../components/seo"
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Footer from './../components/layouts/Footer'
import ContactBanner from './../components/ContactBanner'
import ContactForm from './../components/ContactForm'

export default function Contact() {
    const {t} = useTranslation();
    return (
        <MainLayout enableSticky>
            <Seo title={t('Contact')} />
            <ContactBanner/>
            <ContactForm/>
            <Footer/>
        </MainLayout>
    )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
