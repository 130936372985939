import React, { useState } from 'react' 
import { Grid, Container, Button, TextField, CircularProgress } from '@material-ui/core'
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import * as Toast from '../../utils/toast';
import clientAxios from '../../services/axios';
import { API_V1 } from '../../contants/apis';

export default function ContactForm() {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            names: '',
            lastnames: '',
            subject: '',
            email: '',
            message: ''
        },
        validationSchema: Yup.object({
            names: Yup
                .string()
                .required(`El nombre es requerido`),
            lastnames: Yup
                .string()
                .required('Los apellidos son requeridos'),
            subject: Yup
                .string()
                .required('El asunto es requerido'),
            email: Yup
                .string()
                .email('El email es inválido')
                .required('El email es requerido'),
            message: Yup
                .string()
                .required('El mensaje es requerido')
        }),
        onSubmit: async (formData) => {
            sendContactInfo(formData);
        }

    })

    const sendContactInfo = (data) => {
        setLoading(true);
        clientAxios.post(`${API_V1}/website/contact`, data)
            .then(response => {
                console.log(response.data);
                Toast.toastSuccess('La información se envió correctamente');
            })
            .catch(e => {
                console.log(e.message);
                Toast.toastSuccess(e.message);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <div className='contact-form' >
            <Container 
            data-sal="slide-up"
            data-sal-duration="2000"
            className='contact-form-container'>
                <Grid container>
                    <Grid item xs={12} md={7} className='form-fields'>
                        <form noValidate autoComplete="off" className='form' onSubmit={formik.handleSubmit}>
                            <Grid container>
                                <Grid item xs={12} md={12}>
                                    <Grid container>
                                        <h2>{t('Contact Form Title')}</h2>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Grid container>
                                        <TextField
                                            id="names"
                                            fullWidth
                                            label={t('Contact Form Name')}
                                            onChange={formik.handleChange}
                                            value={formik.values.names}
                                            error={formik.touched.names && formik.errors.names}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Grid container>
                                        <TextField
                                            id="lastnames"
                                            fullWidth
                                            label={t('Contact Form LastName')}
                                            onChange={formik.handleChange}
                                            value={formik.values.lastnames}
                                            error={formik.touched.lastnames && formik.errors.lastnames}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Grid container>
                                        <TextField
                                            id="email"
                                            fullWidth
                                            label={t('Contact Form Email')}
                                            onChange={formik.handleChange}
                                            value={formik.values.email}
                                            error={formik.touched.email && formik.errors.email}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Grid container>
                                        <TextField
                                            id="subject"
                                            fullWidth
                                            label={t('Contact Form Subject')}
                                            onChange={formik.handleChange}
                                            value={formik.values.subject}
                                            error={formik.touched.subject && formik.errors.subject}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Grid container>
                                        <TextField
                                            id="message"
                                            label={t('Contact Form Message')}
                                            multiline
                                            rows={4}
                                            fullWidth
                                            onChange={formik.handleChange}
                                            value={formik.values.message}
                                            error={formik.touched.message && formik.errors.message}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Grid container justify='flex-end'>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type='submit'
                                            disabled={loading}
                                        >
                                            {!loading && t('Contact Form Submit')}
                                            {loading && <CircularProgress color='inherit' size={20} />}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid> 
                        </form>
                    </Grid>
                    <Grid item xs={12} md={5} className='form-info'>
                        <Grid container direction='column'>
                            <h2>{t('Contact Info')}</h2>
                            <br></br>
                            <div className='form-info-item'>
                                <StaticImage src='./../../images/icon-phone.png' alt='phone' />
                                <p>+57 3043207917</p>
                            </div>
                            <div className='form-info-item'>
                                <StaticImage src='./../../images/icon-email.png' alt='email' />
                                <p>waitnrestcontact@gmail.com</p>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}
